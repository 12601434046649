import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { urlSanitizerV2 } from "../../services/url-sanitization.service";
import { MathText } from "../mathJax/MathText.jsx";
import "./Blog.css";
import { BlogData } from "./Data";
import BlogFaq from "./Faq";
import PopularBlogs from "./PopularBlog";
import ShareButtons from "./ShareButton";
const BlogPost = () => {
  const { topic } = useParams();

  const blogIndex = BlogData.findIndex(blog => urlSanitizerV2(blog.title) === topic);

  const blog = blogIndex !== -1 ? BlogData[blogIndex] : null;
  if (!blog) {
    return <div>Blog not found</div>;
  }

  const renderPreparationStrategies = (strategies) => {
    const scrollToSection = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    // Calculate the value of hasFaq based on the presence of FAQ data
    return strategies?.map((strategy, index) => {
      if (strategy?.type === "text") {
        return <MathText key={index} text={strategy?.content} textTag="p" className="black-text" />;
      } else if (strategy?.type === "image") {
        return (
          <img key={index} src={strategy?.imageUrl} alt={strategy?.altText} />
        );
      }
      else if (strategy?.type === "multi_col_table") {
        return (
          <div key={index}>
            <div className="table-body">
              <h4>{strategy?.table?.title}</h4>
              <table className="custom-table">
                <thead>
                  <tr>
                    {Object.keys(strategy?.table?.data[0]).map((header, idx) => (
                      <th key={idx}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {strategy?.table?.data?.map((row, idx) => (
                    <tr key={idx}>
                      {Object.values(row).map((value, idx) => (
                        <td key={idx}>{value}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <br />
          </div>
        );
      }
      else if (strategy?.type === "single_col_table") {
        return (
          <div key={index}>
            <div className="table-body">
              <h4>{strategy?.table?.title}</h4>
              <div style={{ padding: "10px 15px" }}>
                <div className="">
                  {strategy?.table?.data?.map((cell, idx) => (
                    <div key={idx} className="mr-4" onClick={() => scrollToSection(urlSanitizerV2(cell))}>
                      <MathText text={"☛  " + cell} textTag="span" />
                    </div>
                  ))}

                </div>
              </div>
            </div>
            <br />
          </div>
        );
      } else if (strategy?.type === "also_read") {
        return (
          <>
            <h2 id="readfaq">Also Read</h2>
            <ul>
              {strategies?.alsoRead?.map((item, index) => (
                <MathText key={index} text={item} textTag="li" />
              ))}
            </ul>
          </>
        );
      } else if (strategy.type === "conclusion") {
        return (
          <>
            <h2>Conclusion</h2>
            <p>{blog?.conclusion}</p>
          </>
        );
      }
      // else if (strategy.type === "new_para") {
      //   return (
      //     <div className="new_para">
      //       <div className="heading">
      //         <h4 className="m-0">{strategy.heading}</h4>
      //       </div>
      //       <div className="description">
      //         <MathText text={strategy.content} textTag="p" />
      //       </div>
      //       <div className="read_more">
      //         {strategy.alsoRead && strategy.alsoRead.length > 0 && (
      //           <div>
      //             <strong>ALSO READ -</strong>
      //             {strategy.alsoRead.map((item, index) => (
      //               <span style={{ color: item.color }} key={index}>
      //                 &nbsp;
      //                 <a
      //                   href={item.url}
      //                   target="_blank"
      //                   rel="noopener noreferrer"
      //                 >
      //                   {item.title}
      //                 </a>
      //               </span>
      //             ))}
      //           </div>
      //         )}
      //       </div>
      //     </div>
      //   );
      // }
      else if (strategy?.type === "new_para") {
        return (
          <div className="new_para" key={index}>
            <div className="heading" id={`${urlSanitizerV2(strategy.heading)}`}>
              <h4 className="m-0">{strategy.heading}</h4>
            </div>
            <div className="description">
              <MathText text={strategy.content} textTag="p" />
              {strategy.list && (
                <ul>
                  {strategy.list.map((item, idx) => (
                    <MathText text={item} key={idx} textTag="li" />
                  ))}
                </ul>
              )}
              {strategy.sentence && (
                <div className="sentence">
                  <p>{strategy.sentence}</p>
                </div>
              )}
            </div>

            <div className="read_more">
              {strategy.alsoRead && strategy.alsoRead.length > 0 && (
                <div >
                  <strong>ALSO READ -</strong>
                  {strategy.alsoRead.map((item, index) => (
                    <span style={{ color: item.color }} key={index}>
                      &nbsp;
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.title}
                      </a>
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      }
      else {
        return <div></div>
      }
    });
  };

  const renderTableOfContents = (tableOfContents, hasFaq) => {
    const scrollToSection = (id) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };

    if (!tableOfContents) {
      return null;
    }

    return (
      <div className="table-of-contents">
        <h3>{tableOfContents.title}</h3>
        <ul>
          {tableOfContents.data.map((item, index) => (
            <li key={index}>
              <a
                href={`#${urlSanitizerV2(item)}`}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection(urlSanitizerV2(item));
                }}
                className="cursor-pointer"
              >
                {item}
              </a>
            </li>
          ))}
          {/* {hasFaq && (
            <li>
              <a
                href="#faq"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToSection("faq");
                }}
                className="cursor-pointer"
              >
                FAQ
              </a>
            </li>
          )} */}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <section id="page-title">
        <div className="page-title max-w-[1280px] mx-auto">
          <h1>{blog?.title}</h1>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/" style={{ textTransform: "uppercase" }}>
                  DPS Greater Noida{" "}
                </Link>
              </li>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="mx-auto max-w-[1280px]">
        <div id="blog" className="single-post">
          <div className="post-item flex flex-wrap">
            <div className="post-item-wrap mb-8 md:max-w-[70%] md:flex-1 md:order-1">
              <div className="post-image">
                <img src={blog.image.url} alt={blog.image.alt} />
              </div>
              <div className="post-item-description">
                <h2>{blog?.title}</h2>
                <div className="text-justify">
                  {renderTableOfContents(blog?.tableOfContents)}
                  {renderPreparationStrategies(blog?.preparationStrategies)}
                </div>
                <div id="faqs" className="mt-5">
                  <ShareButtons />
                </div>
              </div>
            </div>
            <div className="popular-blogs md:max-w-[30%] md:order-2">
              <PopularBlogs />
            </div>
          </div>
        </div>

      </section>
      <div> <BlogFaq /></div>
    </div>
  );
};

export default BlogPost;