import { useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import ExamInfo from "../../components/home/ExamInfo";

const Banner = () => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handlescroll = () => {
    window.scrollTo({
      top: 600,
      behavior: 'smooth'
    });
  };

  const handleBellIconClick = () => {
    setIsPopupOpen(true);
  };

  const handleCloseExamInfo = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="mx-auto relative mb-8">
      <div className=" relative max-md:flex max-md:flex-col max-md:justify-center max-md:gap-4  items-center ">
        <div className=" flex flex-col justify-center items-center   ">
          <img className="w-screen  md:h-[85vh] object-cover" alt=" " src={require("../../assets/dps-images/dps.jpeg")} />
        </div>
        <div className="flex bottom-0 md:left-8 md:absolute gap-10 justify-center items-center ">
          <div className="flex flex-col max-md:rounded-md max-md:gap-2 max-md:mx-2 max-md:bg-[#000000e8] max-md:w-[80vw] max-sm:w-[90vw] max-md:text-5xl max-sm:text-3xl justify-center  md:max-w-[45vw] ">
            <div className="backdrop-blur-[2px] max-md:mx-auto md:w-[80%] rounded-lg max-md:p-3 p-2  md:bg-[#0000008a]">
              <div className="my-4 font-bold text-3xl   md:text-14xl">
                <p className="m-0 max-md:whitespace-nowrap text-white">One Stop Solution for your</p>
                <p className="m-0">
                  <span className="text-white">all  </span>
                  <span className="text-10xl md:text-[35px] text-[#FF820F]">CUET</span>
                  <span className="text-7xl md:text-14xl text-white"> needs</span>
                </p>
              </div>
              <div className="mb-4 text-white">
                <b className="h-12 relative max-w-full font-semibold text-md">
                  <p className="m-0">Avail Complete Support from India’s best trainers</p>
                </b>
              </div>
              <div>
                <h3 className="m-0 h-[58px] relative font-bold font-inherit text-white inline-block text-lg md:text-md ">
                  <p className="m-0">Trusted by Students & Teachers across INDIA</p>
                </h3>
              </div>
            </div>
            <div className="my-6 max-md:mt-[-5px] w-[80%] max-md:mx-auto  flex justify-between">
              <button
                onClick={() => navigate("/purchase")}
                className={
                  "btn text-sm md:text-lg font-semibold md:px-8 text-white bg-[#FF820F] hover:bg-[#FF820F]   no-underline rounded-full border-0 min-h-0 h-10  max-md:w-35   capitalize"
                }
              >
                Join Us
              </button>
              <button
                onClick={handlescroll}
                className={
                  "btn btn-outline text-sm md:text-lg  md:px-8 border-white md:bg-white font-semibold text-white md:text-[#FF820F] no-underline border-solid  hover:bg-black hover:text-white rounded-full min-h-0 h-10 max-md:w-[50%] whitespace-nowrap  capitalize"
                }
              >
                Take a free test
              </button>
            </div>
          </div>

        </div>
        <div className=" absolute right-2 top-2 gap-2  flex justify-center items-start ">
          <div className="flex flex-col justify-center items-center relative max-lg:hidden text-base cursor-pointer">
            <div className=" flex gap-2  relative justify-center items-center" onClick={handleBellIconClick}>
              <div className="relative">
                <span className="bg-[#c92e2e] flex justify-center items-center text-[8px] font-bold text-white rounded-full p-1 text-center h-[6px] w-[6px] z-10 absolute top-[-4px] right-[-4px]">1</span>
                <img
                  className="h-[30px] bg-[#ffffff] p-1 rounded-md  w-[30px] relative object-cover"
                  loading="eager"
                  alt=""
                  src={require("../../assets/images/signup/bellicon.gif")}
                />
              </div>

              <div className="h-[30px] items-center font-semibold   pr-5 p-1 bg-white rounded-md flex gap-3 justify-center ">
                <h className="gradient-text2 flex justify-center items-center text-[12px]">
                  CUET
                </h>
                <h className="m-0 text-[12px] ">Published on February 27, 2024 <br />
                  Closing on March 26, 2024, at 11:50 PM
                </h>
              </div>
            </div>
          </div>



        </div>
        <div className="absolute md:w-[25rem]  top-16 right-[5.7rem] max-md:left-0 ">
          {isPopupOpen && (
            <ExamInfo onClose={handleCloseExamInfo} />
          )}
        </div>

      </div>


    </div>

  );
};

export default Banner;