import React from "react";
import { FaArrowRight, FaRegBuilding } from "react-icons/fa";
import { IoIosTimer, IoMdCall, IoMdMail, IoMdPin } from "react-icons/io";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer
      className="bg-black text-white overflow-hidden w-full flex justify-center items-center relative z-50"
    >
      <div className="w-full max-md:mx-5 overflow-hidden">
        <div className="flex  mt-6 mb-3 ">
          <div className=" flex flex-col center gap-8 md:flex-row md:mx-10  ">
            <div className=" md:flex md:w-[50%] md:text-[1.2vw] max-md:text-[2.5vw] max-sm:text-[3vw] md:flex-col justify-center  max-md:ml-[0px] ">
              <div className="flex justify-start items-center ">
                <Link
                  to="/"
                  className="no-underline d-flex justify-content-center mt-4 align-items-center flex-column"
                >
                  <div className="flex items-center">
                    <div className="mr-4 mb-[-12px]">
                      <img
                        src={"/dps-logo.png"}
                        alt="Logo"
                        className="max-h-[60px] max-w-[60px] pr-4 pb-2"
                      />
                    </div>
                    <div className="text-white font-sans font-bold text-[100%] md:ml-[-8%]">
                      <h2>
                        <span className=" whitespace-nowrap">DPS Greater Noida</span>
                      </h2>
                    </div>
                  </div>
                </Link>
              </div>
              <p className="max-md:pb-3 font-normal text-justify">
                DPS Greater Noida one of the Core Schools under the control and support of the DPS Society New Delhi, having commenced its operations effectively in 1998, has certainly come of an age, with a strength of 4650 students (approx.) and 222 dedicated faculty members now, from classes Nursery to XII. Situated in a very systematically planned township forming the suburb of Delhi, it has been imparting a holistic education to its lively students under the guidance of dynamic principals, since its inception, who have acted as the true mentors.
              </p>
              <a target="_blank" href="https://dpsgrnoida.in/dpsgrnoida/userspace/username/dpsnadmin/DynamicFolder/dps2021/infrastruture.html" className="text-black max-md:mt-8" rel="noreferrer">
                <span className="bg-white whitespace-nowrap     gap-1  p-3 r rounded-tl-2xl rounded-br-2xl">

                  Learn More
                  <span className=" relative top-1 ml-1"><FaArrowRight /></span>

                </span>


              </a>
            </div>
            <div className="relative md:top-[25px] gap-4 grid md:grid-cols-3   grid-cols-1  text-sm md:text-md  ">
              <div className="min-w-[10em] md:text-[1.2vw] max-md:text-[2.5vw] max-sm:text-[3vw] mx-auto md:my-0 ">
                <p className=" font-bold mb-10">Contact Us</p>
                <a
                  href="https://maps.google.com/?q=Sector+Gamma.+II,+Greater+Noida+City+Distt,+Gautam+Budh+Nagar,+U.P.-201310" target="_blank" rel="noreferrer"
                  className="hover:opacity-80 no-underline"
                >
                  <p className="flex justify-between my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                    <span>
                      <IoMdPin className="text-[1.1em] md:text-[1vw]" />
                    </span>
                    <span className="ml-2">
                      DELHI PUBLIC SCHOOL GREATER NOIDA Sector Gamma - II, Greater Noida City Distt, Gautam Budh Nagar, U.P.-201310                    </span>
                  </p>
                </a>
                <a
                  href="tel:91 -120-4612 300/ 301/ 302/ 303/ 304/ 305/ 306/ 307"
                  className="hover:opacity-80 no-underline"
                >
                  <p className=" font-normal flex my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                    <span className="flex mr-2">
                      <IoMdCall className="text-[1.1em] md:text-[1vw]" />
                    </span>
                    91 - 120 - 4612 300/ 301/ 302/
                    303/ 304/ 305/ 306/ 307
                  </p>
                </a>

                <p
                  className="hover:opacity-80 no-underline"
                >
                  <p className="md:text-center font-normal flex items-center my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                    <span className="flex justify-center items-center mr-2">
                      <IoIosTimer className="text-[1.1em] md:text-[1vw]" />
                    </span>
                    Mon - Fri: 8.00 AM - 02.00 PM
                  </p>
                </p>
                <a
                  href="mailto:dpsnadmin@dpsgrnoida.com"
                  className="hover:opacity-80 no-underline"
                >
                  <p className="md:text-center font-normal flex items-center my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                    <span className="flex justify-center items-center mr-2">
                      <IoMdMail className="text-[1.1em] md:text-[1vw]" />
                    </span>
                    <span className="text-[90%]">dpsnadmin@dpsgrnoida.com</span>
                  </p>
                </a>
                <p className="flex justify-start gap-2 items-center" >
                  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC5gcF-eMsFEHYMUWMO5UHIg"><img src={require("../assets/dps-images/ytb.png")} alt="" /></a>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/dpsgreaternoidaschool/"><img className="max-w-[30px]" src={require("../assets/dps-images/instagram-logo.png")} alt="" /></a>
                  <a href="https://www.google.com/maps/place/DPS+Greater+Noida/@28.4925391,77.5052786,17z/data=!3m1!4b1!4m5!3m4!1s0x390cea5d062493d3:0x4e7d91942d3ac1dd!8m2!3d28.4923585!4d77.5075339" target="_blank" rel="noreferrer"
                  >
                    <p className="md:text-center md:hidden font-normal flex items-center my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                      Our Route Map
                    </p>
                  </a>
                </p>
                <a href="https://www.google.com/maps/place/DPS+Greater+Noida/@28.4925391,77.5052786,17z/data=!3m1!4b1!4m5!3m4!1s0x390cea5d062493d3:0x4e7d91942d3ac1dd!8m2!3d28.4923585!4d77.5075339" target="_blank" rel="noreferrer"
                >
                  <p className="md:text-center max-md:hidden font-normal flex items-center my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                    Our Route Map
                  </p>
                </a>

              </div>
              <div className="min-w-[10em] md:text-[1.2vw] max-md:text-[2.5vw] max-sm:text-[3vw] mx-auto  ">
                <p className=" font-bold max-md:text-center md:mb-10">Digital School Mobile App
                </p>
                <p className="flex gap-2 justify-center items-center">

                  <p2
                    className="hover:opacity-80 max-md:hidden no-underline"
                  >
                    <img className="w-full" src={require("../assets/dps-images/digital1.png")} alt="" />
                  </p2>
                  <p
                    className=" md:flex-col flex-row flex gap-4 no-underline"
                  >
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/in/app/digital-school/id953446658">                    <img className="w-full hover:opacity-80" src={require("../assets/dps-images/g1.png")} alt="" />
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.digitalschool&pli=1">                    <img className="w-full hover:opacity-80" src={require("../assets/dps-images/g2.png")} alt="" />
                    </a>

                  </p>

                </p>
              </div>
              <div className="min-w-[10em] md:text-[1.2vw] max-md:text-[2.5vw] max-sm:text-[3vw]  max-md:hidden mx-auto my-5 md:my-0 ">
                <p className="  font-bold mb-10">Any Technical Issues</p>
                <p className="flex flex-col gap-3">

                  <a
                    href="https://www.testknock.com/" target="_blank" rel="noreferrer"
                    className="hover:opacity-80 no-underline"
                  >
                    <p className="md:text-center font-normal flex items-center my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                      <span className="flex justify-center items-center mr-2">
                        <FaRegBuilding className="text-[1.1em] md:text-[1.2vw]" />
                      </span>
                      TestKnock Technologies
                    </p>
                  </a>
                  <a
                    href="mailto:info@testknock.com"
                    className="hover:opacity-80 no-underline"
                  >
                    <p className="md:text-center font-normal flex items-center my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                      <span className="flex justify-center items-center mr-2">
                        <IoMdMail className="text-[1.1em] md:text-[1.2vw]" />
                      </span>
                      info@testknock.com
                    </p>
                  </a>
                  <a
                    href="tel:91 -120-4612 300/ 301/ 302/ 303/ 304/ 305/ 306/ 307"
                    className="hover:opacity-80 no-underline"
                  >
                    <p className="md:text-center font-normal flex items-center my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                      <span className="flex justify-start items-center mr-2">
                        <IoMdCall className="text-[1.1em] md:text-[1.2vw]" />
                      </span> +91- 9953617456
                    </p>
                  </a>
                  <a
                    href="https://maps.google.com/?q=Sector+Gamma.+II,+Greater+Noida+City+Distt,+Gautam+Budh+Nagar,+U.P.-201310" target="_blank" rel="noreferrer"
                    className="hover:opacity-80 no-underline"
                  >
                    <p className="flex justify-between my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                      <span>
                        <IoMdPin className="text-[1.1em] md:text-[1.2vw]" />
                      </span>
                      <span className="ml-2">
                        Office No. - 42, <br /> Durga Vihar, <br /> near Amrapali Saphhire, <br /> Sec-45, Noida-201301                    </span>
                    </p>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-10 grid-cols-2 text-[3vw]">

          <div className="min-w-[10em] md:text-[1.2vw] max-md:text-[2.5vw] max-sm:text-[3vw] md:hidden mx-auto md:my-0 md:ml-[29px]">
            <p className=" font-bold mb-10">Any Technical Issues</p>
            <a
              href="mailto:info@testknock.com"
              className="hover:opacity-80 no-underline"
            >
              <p className="md:text-center font-normal flex items-center my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                <span className="flex justify-center items-center mr-2">
                  <IoMdMail className="text-[1.1em] md:text-[1vw]" />
                </span>
                info@testknock.com
              </p>
            </a>
            <a
              href="tel:91 -120-4612 300/ 301/ 302/ 303/ 304/ 305/ 306/ 307"
              className="hover:opacity-80 no-underline"
            >
              <p className="md:text-center font-normal flex items-center my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                <span className="flex justify-start items-center mr-2">
                  <IoMdCall className="text-[1.1em] md:text-[1vw]" />
                </span> 9953617456
              </p>
            </a>
            <a
              href="https://maps.google.com/?q=Sector+Gamma.+II,+Greater+Noida+City+Distt,+Gautam+Budh+Nagar,+U.P.-201310"
              className="hover:opacity-80 no-underline"
            >
              <p className="flex justify-between my-3 text-white hover:text-blue-500 hover:cursor-pointer">
                <span>
                  <IoMdPin className="text-[1.1em] md:text-[1vw]" />
                </span>
                <span className="ml-2">
                  Office No. - 42, Durga Vihar, near Amrapali Saphhire, Sec-45, Noida-201301                    </span>
              </p>
            </a>
          </div>
          <div className="min-w-[10em] md:text-[1.2vw] max-md:text-[2.5vw] max-sm:text-[3vw] mx-auto md:hidden">
            <p className="font-bold mb-10  md:text-center">Support</p>
            <p>  <a href="/Terms and Conditions for TestKnock.pdf" target="_blank" className="my-3 text-white no-underline md:text-center">Terms & Conditions</a></p>
            <p> <a href="/Cancellation and Refund Policy for TestKnock .pdf" target="_blank" className="my-3 text-white no-underline md:text-center">Cancellation/Refund Policy</a></p>
            <p> <a href="/Privacy Policy for TestKnock.pdf" target="_blank" className="my-3 text-white no-underline md:text-center">Privacy Policy</a></p>
          </div>
        </div>

        <div className="hidden md:flex mx-auto flex-col my-3 md:text-[1.2vw] max-md:text-[2.5vw] max-sm:text-[3vw]  items-start justify-start py-0 px-px box-border gap-[15px] max-w-full ">
          <hr className="w-[100%] mx-auto  text-white" />
          <div className="w-[90%] mx-auto flex flex-row items-start justify-start py-0 box-border max-w-full">
            <div className="flex-1 flex flex-row items-start justify-between max-w-full ">
              <div className="flex flex-row items-start justify-start max-w-full mq750:flex-wrap mq450:gap-[76px]">
                <div className="flex flex-row items-start justify-start  mr-4">
                  &copy;
                  <b className="mr-4 mb-[-12px] ml-2 relative z-[1]">
                    2024 TestKnock
                  </b>
                </div>
                <b className="mr-4 mb-[-12px] relative z-[1]">
                  All rights reserved
                </b>
              </div>
              <div className="flex flex-row items-start justify-start gap-[20px] text-white">
                <a href="/Terms and Conditions for TestKnock.pdf" target="_blank" className="relative z-[1] text-white font-bold no-underline hover:text-blue-500 hover:cursor-pointer">
                  Terms & Conditions
                </a>
                <b className="relative z-[1]">|</b>
                <a href="/Cancellation and Refund Policy for TestKnock .pdf" target="blank" className="relative z-[1] text-white font-bold no-underline hover:text-blue-500 hover:cursor-pointer">
                  Cancellation/Refund Policy
                </a>
                <b className="relative z-[1]">|</b>
                <a href="/Privacy Policy for TestKnock.pdf" target="blank" className="relative z-[1] text-white font-bold no-underline hover:text-blue-500 hover:cursor-pointer">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;