import React from "react";
import email from "./blogImages/email.png";
import facebook from "./blogImages/facebook.png";
import telegram from "./blogImages/telegram.png";
import twitter from "./blogImages/twitter.png";
import whatsap from "./blogImages/whatsapp.png";
import { Link } from "react-router-dom";

const ShareButtons = () => {
  const currentUrl = window.location.href;

  const shareOnPlatform = (platform) => {
    switch (platform) {
      case 'facebook':
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            currentUrl
          )}`,
          "_blank"
        );
        break;
      case 'whatsapp':
        window.open(
          `https://wa.me/?text=${encodeURIComponent(currentUrl)}`,
          "_blank"
        );
        break;
      case 'telegram':
        window.open(
          `https://t.me/share/url?url=${encodeURIComponent(currentUrl)}`,
          "_blank"
        );
        break;
      case 'twitter':
        window.open(
          `https://twitter.com/share?url=${encodeURIComponent(
            currentUrl
          )}&text=${encodeURIComponent("Check out this blog post!")}`,
          "_blank"
        );
        break;
        case 'email':
          window.location.href = `mailto:?subject=Check out this blog post!&body=${encodeURIComponent(currentUrl)}`;
          break;
        default:
          break;
    }
  };

  return (
    <div className="flex-col flex justify-center items-start gap-3 float-left ml-[-18px] space-x-4 w-[70%] bg-slate h-auto rounded-lg ">
      <strong className="ml-[18px]">Share</strong>
      <div className="flex items-center justify-center float-left gap-3 ml-[-20px] leading-4">
        <Link to="" onClick={() => shareOnPlatform("facebook")}>
          <img src={facebook} className="h-[28px] w-[28px]" alt="Facebook" />
        </Link>
        <Link to="" onClick={() => shareOnPlatform("whatsapp")}>
          <img src={whatsap} className="h-[28px] w-[28px]" alt="WhatsApp" />
        </Link>
        <Link to="" onClick={() => shareOnPlatform("telegram")}>
          <img src={telegram} className="h-[28px] w-[28px]" alt="Telegram" />
        </Link>
        <Link to="" onClick={() => shareOnPlatform("twitter")}>
          <img src={twitter} className="h-[28px] w-[28px]" alt="Twitter" />
        </Link>
        <Link to="" onClick={() => shareOnPlatform("email")}>
          <img src={email} className="h-[28px] w-[28px]" alt="Email" />
        </Link>
      </div>
    </div>
  );
};

export default ShareButtons;
