import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import AboutHero from "../components/about/AboutHero";
import AboutHero2 from "../components/about/AboutHero2";
import AboutHero3 from "../components/about/AboutHero3";
import AboutHero4 from "../components/about/AboutHero4";

const AboutPage = () => {
  return (
    <div className="w-full overflow-hidden  ">
      <Navbar />
      <AboutHero />
      <AboutHero2 />
      <AboutHero3 />
      <AboutHero4 />
      <Footer />
    </div>
  );
};

export default AboutPage;