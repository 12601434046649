import React from 'react';
import AboutContainer from './AboutContainer';
const subtopicsData1 = [
  {
    title: "School Students",
    image: require("../../assets/images/about/school.png"),
    color: "bg-[#9B52F2]",
  },
  {
    title: "College Students",
    image: require("../../assets/images/about/college.png"),
    color: "bg-[#FF7A7A]",


  },
  {
    title: "Private College",
    image: require("../../assets/images/about/private.png"),
    color: "bg-[#4797FF]",

  },
  {
    title: "Educational Institutions",
    image: require("../../assets/images/about/university.png"),
    color: "bg-[#FF9A4F]",

  },
];
const AboutHero2 = () => {
  return (
    <div >
      <div>
        <h1 className='text-center'>Whom we serve</h1>
        <div className="flex max-w-[1380px] mx-auto items-center justify-center">
          <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4  mx-3 my-8">
            {subtopicsData1.map((item, index) => (
              <AboutContainer
                key={index}
                text_color={"white"}
                img_path={item.image}
                text={item.title}
                color={item.color}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='  max-w-[1580px] mx-auto  '>
        <div className='flex md:flex-row rounded-xl bg-gradient-to-tr from-[#FFE8F5] to-[#E3FFF6] mx-[10px] md:mx-[50px]    flex-col py-[20px] md:py-[80px]   p-4  justify-between items-center '>
          <div className=''>
            <h1 className="  font-bold text-xl md:text-7xl">Why Choose Us?</h1>
            <p className="font-normal md:font-medium lg:max-w-[90%]  md:text-xl text-[17px]">
              Choosing TestKnock means embarking on a transformative expedition towards educational excellence. Unlike
              conventional institutions, we prioritize personalized learning experiences tailored to individual needs and aspirations.  <br /> <br />
              Our dedicated team is committed to providing unparalleled support, mentorship, and resources to ensure every student reaches their full potential. From comprehensive study materials to interactive workshops and beyond, we offer a diverse range of opportunities designed to inspire and empower learners at every stage of their journey. <br /> <br />
              Whether you're preparing for exams, pursuing higher education, or simply seeking personal growth, TestKnock is where aspiration meets achievement, and educational excellence becomes an inspiring and achievable pursuit.
            </p>
          </div>
          <div >
            <img alt="" src={require("../../assets/images/about/hero3 (1).png")} className=" max-sm:w-[80vw] max-md:w-[400px] max-lg:w-[300px] sm:max-w-[400px] " />
          </div>

        </div>
      </div>

    </div>
  )
}

export default AboutHero2