import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const timelineItems = [
  {
    text: "Access a wide range of practice tests covering various entrance and higher education exams in India, helping you familiarize yourself with the exam format and question types.",
    title: "Comprehensive Practice Tests",
    subtitle: null,
    image: require("../../assets/images/about/Archer.png"),
  },
  {
    text: "Take simulated mock tests that mimic the real exam environment, allowing you to gauge your preparedness and identify areas for improvement before the actual exam.",
    title: "Mock Tests",
    subtitle: null,
    image: require("../../assets/images/about/form.png"),
  },
  {
    text: "Receive immediate feedback with instant scorecards after completing practice tests and mock tests, enabling you to track your progress and focus on areas that require more attention.",
    title: "Instant Scorecards",
    subtitle: null,
    image: require("../../assets/images/about/chart.png"),
  },
  {
    text: "Explore comprehensive syllabi for every subject, ensuring that you study and practice all relevant topics required for your exams effectively.",
    title: "Detailed Syllabus Coverage",
    subtitle: null,
    image: require("../../assets/images/about/folder.png"),
  },
  {
    text: "Enjoy a personalized learning experience with tailored recommendations based on your performance in practice tests, helping you focus your efforts on areas where you need the most improvement.",
    title: "Personalized Learning Experience",
    subtitle: null,
    image: require("../../assets/images/about/computer.png"),
  },
  {
    text: "Study at your own pace and convenience with 24/7 access to the platform from anywhere with an internet connection, allowing you to fit your exam preparation around your schedule.",
    title: "Flexibility and Convenience",
    subtitle: null,
    image: require("../../assets/images/about/people.png"),
  },
];

const AboutHero4 = () => {
  return (
    <div>
      <div>
        <h1 className='text-[#FF820F] text-center'>What are you getting from TestKnock?</h1>
      </div>
      <VerticalTimeline lineColor="#FF820F">
        {timelineItems.map((item, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentStyle={{
              background: 'linear-gradient(to right, #FFFDCB, #FFB38E)',
            }}
            iconStyle={{
              background: '#FF820F',
              color: '#fff',
              display: 'flex',
              marginTop: "60px",
              justifyContent: 'center',
              alignItems: 'center',
            }}
            icon={index + 1}
            date={<img src={item.image} alt={item.title} className="w-28 h-20 mt-[40px]" />}
            contentArrowStyle={{
              marginTop: "60px",
              borderRight: `7px solid ${index % 2 === 0 ? '#FFB38E' : '#FFFDCB'}`,
            }}
          >

            <h3 className="vertical-timeline-element-title">{index + 1}. {item.title}</h3>
            {item.subtitle && <h4 className="vertical-timeline-element-subtitle">{item.subtitle}</h4>}
            <p>{item.text}</p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
}

export default AboutHero4;
