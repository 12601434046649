import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    accessToken: "",
  });
  const [loading, setLoading] = useState(true);

  axios.defaults.headers.common["Authorization"] = auth?.accessToken;

  const isTokenExpired = (expiryTime) => {
    const currentTime = Date.now();
    return currentTime > expiryTime;
  };

  useEffect(() => {
    const loadAuthData = () => {
      const data = localStorage.getItem("auth");
      if (data) {
        try {
          const parsedData = JSON.parse(data);

          const tokenExpiry = parsedData.accessToken?.expiry;

          if (tokenExpiry && isTokenExpired(tokenExpiry)) {
            localStorage.removeItem("auth");
            setAuth({
              user: null,
              accessToken: "",
            });
          } else {
            setAuth({
              user: parsedData.user || null,
              accessToken: parsedData.accessToken || "",
            });
          }
        } catch (error) {
          console.error(
            "Failed to parse authentication data from local storage:",
            error
          );
        }
      }
      setLoading(false);
    };

    loadAuthData();
  }, []);

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {!loading ? children : null}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
