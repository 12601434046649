import React from 'react';
import "./about.css";
const AboutHero = () => {
    return (
        <div>
            <div className=' bg-gradient-to-b from-[#FFFFFF] to-[#ff830fa8] relative'>
                <div className='flex md:flex-row max-w-[1380px] mx-auto flex-col py-[20px] md:py-[80px] md:pb-[180px]    justify-between  items-center px-[10px] md:px-[50px]'>
                    <div className=" text-center mx-auto w-[80%]">
                        <h1 className="  font-bold text-xl md:text-13xl "><span className="text-whitepx-4 py-1 rounded-lg">We are on a mission to empower students with affordable
                            training resources tailored to their needs.</span></h1>
                        <p className="font-normal md:font-medium lg:max-w-[50%] opacity-95  mx-auto  md:text-xl text-[17px] ">
                            Education for all students is crucial to our company. We are
                            committed to giving them the necessary tools and resources
                            that will enable them achieve their academic goals as well as
                            career aspirations at an affordable cost. Our purpose is to
                            prepare students for a highly competitive world.
                        </p>
                    </div>


                </div>
                <div>

                </div>
                <div class="custom-shape-divider">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
            </div>


            <div className='flex max-w-[1380px] mx-auto md:flex-row flex-col-reverse  gap-2  bg-white  justify-between py-[20px] md:py-[80px] items-center px-[10px]  md:px-[60px]'>
                <div className='relative md:top-[-50px]'>
                    <img alt="" src={require("../../assets/images/about/hero.png")} className=" max-sm:w-[80vw] max-md:w-[400px] max-lg:w-[300px] sm:max-w-[400px] " />
                </div>
                <div className=" text-center relative md:top-[-50px]">
                    <h1 className=" font-bold text-xl md:text-7xl">Who Are We?</h1>
                    <p className="font-normal md:font-medium lg:max-w-[90%] mx-auto  md:text-xl text-[17px] ">
                        At TestKnock, we pride ourselves on being more than just an educational institution. We are a community driven by the belief that academic success is only one aspect of a fulfilling journey. <br /> <br />
                        Our commitment extends beyond traditional boundaries, aiming to empower individual students with the essential tools and expert
                        guidance necessary to navigate the complexities of the modern world. With a focus on holistic development, we nurture a culture that values curiosity, creativity, and critical thinking. <br /> <br />
                        Through our innovative approach to learning, we foster an environment where students can explore their passions, unlock their potential, and cultivate lifelong skills that transcend the confines of the classroom.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default AboutHero