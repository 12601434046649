import React from 'react';

const AboutHero3 = () => {
    return (
        <div className='md:py-[50px] py-[20px]    max-w-[1580px] mx-auto  grid md:grid-cols-3   items-center '>
            <div className='flex bg-gradient-to-br from-[#D6589F] via-[#C4E4FF]  min-h-[350px] md:min-h-[400px] max-md:my-[10px]  to-[#C4E4FF] rounded-xl p-4  justify-between   mx-[10px] lg:mx-[50px]'>
                <div className='flex flex-col justify-center items-center'>
                    <img className='max-w-[100px] mx-auto' src={require("../../assets/images/about/group1.png")} alt="" />
                    <h1 className="  font-bold text-xl md:text-7xl ">For Students</h1>
                    <p className="font-normal md:font-medium lg:max-w-[90%]  md:text-lg text-[17px] text-center">
                        Simplify your college selection process with TestKnock. Explore universities, courses, and admission procedures effortlessly. Our database boasts 500+ institutions in fields like management, engineering, medicine, arts, etc.
                    </p>
                </div>

            </div>
            <div className='flex bg-gradient-to-br from-[#D6589F] via-[#C4E4FF]  min-h-[350px] md:min-h-[400px] max-md:my-[10px]  to-[#C4E4FF] rounded-xl p-4  justify-between   mx-[10px] lg:mx-[50px]'>
                <div className='flex flex-col justify-center items-center'>
                    <img className='max-w-[110px] mx-auto' src={require("../../assets/images/about/Parent and child do homework together.png")} alt="" />
                    <h1 className="  font-bold text-xl md:text-7xl ">For Parents</h1>
                    <p className="font-normal md:font-medium lg:max-w-[90%]  md:text-lg text-[17px] text-center">
                        Seeking the perfect institute for your child? TestKnock offers engaging data presentation for informed decisions. Navigate the educational journey confidently with insights into institutions, courses, and admission procedures.</p>
                </div>
            </div>
            <div className='flex bg-gradient-to-br from-[#D6589F] via-[#C4E4FF]  min-h-[350px] md:min-h-[400px] max-md:my-[10px]  to-[#C4E4FF] rounded-xl p-4  justify-between   mx-[10px] lg:mx-[50px]'>
                <div className='flex flex-col justify-center items-center'>
                    <img className='max-w-[120px] mx-auto' src={require("../../assets/images/about/group3.png")} alt="" />
                    <h1 className="  font-bold text-xl md:text-7xl ">For Universities</h1>
                    <p className="font-normal md:font-medium lg:max-w-[90%]  md:text-lg text-[17px] text-center">
                        TestKnock attracts motivated candidates effectively. Showcase your offerings across disciplines and engage with a diverse audience. Elevate your institution's visibility and position for success in attracting top talent.
                    </p>
                </div>

            </div>

        </div>
    )
}

export default AboutHero3;