
const AboutContainer = ({ img_path, text, color,text_color }) => {
  return (
    <div className={`no-underline  flex flex-col items-center ${color} w-[250px] h-[250px] transition-all duration-100 hover:scale-[101%]  flex justify-center items-center relative z-10 rounded-2xl shadow `}>
      <img className="w-[100px] h-[100px] relative z-10" loading="eager" alt="" src={img_path} />
      <h1 className="text-3xl max-w-[70%] text-white md:text-[25px] text-center">
        {text}
      </h1>
    </div>
  );

};

export default AboutContainer;
