import ReactGA from "react-ga4";

export const trackEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction,
  transport,
}) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
    transport,
  });
};
