import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import "./index.css";
import { AuthProvider } from "./utils/context";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="853968550992-5g0mecja3kfdad87ds4sdilpv1r2ub2k.apps.googleusercontent.com">
    <AuthProvider>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </AuthProvider>
  </GoogleOAuthProvider>
);
